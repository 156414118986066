
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import EditReportsForm from "@/views/apps/research/EditNewsForm.vue";
export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "categories-details",
  components: {
    //EditReportsForm,
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();
    
   interface ViewCategories {
    user: {
        avatar: string;
        thumbnail_image_name: string;
      },  
      report_id,
      report_title,
      report_description,
      report_display_time,
      parent_category_name,
      category_name,
      tag_ids,
      active : {
        label :string;
        color :string;
      }
    };   

    
    var resultsView = ref<ViewCategories>();
    const loadingData = ref<boolean>(true);
    const count = ref(0);

    resultsView.value = {
      user: {
        avatar: "",
        thumbnail_image_name: "",
      },  
      report_id: "",
      report_title: "",
      report_description: "",
      report_display_time: "",
      parent_category_name: "",
      category_name: "",
      tag_ids: "",
      active : {
        label :"",
        color :"",
      }
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

     const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getReportsDetails()
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getReportsDetails()
    }
    

    const getReportsDetails = async () => {
     
      try {

      var values = {"report_id": props.id }

      await store.dispatch(Actions.CUST_REPORT_EDIT, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
        
        resultsView.value = {
          report_id :  data.report_id,
          report_title: data.report_title,
          report_description: data.report_description,
          report_display_time: data.report_display_time,
          tag_ids: data.report_tags_json,
          parent_category_name: data.parent_category_name,
          category_name: data.category_name,
          user : {
                thumbnail_image_name : data.thumbnail_image_name,
                avatar : data.thumbnail_image_name,              
              },
          active: {
            label: active_label,
            color: active_color
          },
        }

      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
   

    onMounted( async () => {
      await getReportsDetails();
      setCurrentPageBreadcrumbs("Reports Details", ["Reports"]);
    });     


    return {
      changePageChange,
      refreshData,
      resultsView,
      loadingData,
      ids,
    };

  }
});
